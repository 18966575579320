@media only screen and (min-width: 1025px) and (max-width: 1200px) {



  li.nav-item {
    padding: 10px 24px !important;
  }

  footer .footer-top .footer-top-content .footer-top-inner a p {
    word-break: break-word;
  }


  .header-row-1 .call-us {
    padding: 0px;
  }


  .header-1-inner {
    grid-gap: 10px;
  }

  .header-1-icon {
    padding: 10px;
    width: 50px;
    height: 50px;
  }

  .header-1-icon img {
    width: 18px;
  }

  .header-1-content h5 {
    font-size: 16px;
  }

  .header-1-content p {
    font-size: 13px;
  }

  .article-row-1 .carousel-caption h5 {
    font-size: 45px;
    line-height: 50px;
    margin-bottom: 20px;
  }

  .article-row-1 .carousel-caption p {
    font-size: 18px;
  }

  .article-row-2 .ar2-right-top-image {
    width: 220px;
  }

  .article-row-2 .ar2-left-bottom-image {
    width: 100px;
  }

  .article-row-3 .about-image-1 {
    width: calc(100% - 150px);
  }

  .article-row-3 .about-image-2 {
    bottom: -80px;
    width: 220px;
  }

  .article-row-4 .ar4-right-image .why-choose-image-1 {
    width: calc(100% - 120px);
  }

  .article-row-4 .ar4-right-image .why-choose-image-2 {
    width: 250px;
  }

  .ar8-carousel .owl-prev {
    left: -45px;
  }

  .ar8-carousel .owl-next {
    right: -45px;
  }

  .about-article-2 .about-image-1 {
    width: calc(100% - 140px);
  }

  .about-article-2 .about-experience {
    width: 45%;
  }

  .about-article-2 .about-image-video-section img {
    width: 200px;
  }

  .about-article-2 .about-image-video-section {
    bottom: 20px;
  }

  .about-article-4 .about-ar4-design-section img {
    width: 300px;
    bottom: 20px;
  }

  .about-ar5-carousel .owl-prev {
    left: -45px;
  }

  .about-ar5-carousel .owl-next {
    right: -45px;
  }

  /* themes */
  .themes-article-2 figure figcaption h5 {
    font-size: 16px;
  }

  .themes-article-2 figure figcaption {
    width: 80%;
    padding: 15px 20px;
  }


  .article-row-8 .testimonial-item {
    height: 100%;
  }


  .about-article-5 .testimonial-item {
    height: 100%;
  }

  .themedetailssecondimg {
    height: 550px;
  }

  .article-row-2 figure img {

    height: 300px !important;

  }

  .bannner {
    height: 430px !important;
  }

  .about-article-6 .gallery-item img {
    height: 386px !important;
  }

  .figcap h5 {
    font-size: 16px !important;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {


  li.nav-item {
    padding: 10px 24px !important;
  }

  footer .footer-top .footer-top-content .footer-top-inner a p {
    word-break: break-word;
  }



  .header-row-1 .call-us {
    padding: 0px;
  }

  .header-1-inner {
    grid-gap: 10px;
  }

  .header-1-icon {
    padding: 10px;
    width: 50px;
    height: 50px;
  }

  .header-1-icon img {
    width: 18px;
  }

  .header-1-content h5 {
    font-size: 16px;
  }

  .header-1-content p {
    font-size: 13px;
  }

  .article-row-1 .carousel-caption h5 {
    font-size: 45px;
    line-height: 50px;
    margin-bottom: 20px;
  }

  .article-row-1 .carousel-caption p {
    font-size: 18px;
  }

  .article-row-2 .ar2-right-top-image {
    width: 220px;
  }

  .article-row-2 .ar2-left-bottom-image {
    width: 100px;
  }

  .article-row-3 .about-image-1 {
    width: calc(100% - 150px);
  }

  .article-row-3 .about-image-2 {
    bottom: -80px;
    width: 220px;
  }

  .article-row-4 .ar4-right-image .why-choose-image-1 {
    width: calc(100% - 120px);
  }

  .article-row-4 .ar4-right-image .why-choose-image-2 {
    width: 250px;
  }

  .ar8-carousel .owl-prev {
    left: -45px;
  }

  .ar8-carousel .owl-next {
    right: -45px;
  }



  /* about us */

  .about-article-2 .about-image-1 {
    width: calc(100% - 140px);
  }

  .about-article-2 .about-experience {
    width: 45%;
  }

  .about-article-2 .about-image-video-section img {
    width: 200px;
  }

  .about-article-2 .about-image-video-section {
    bottom: 20px;
  }

  .about-article-4 .about-ar4-design-section img {
    width: 300px !important;
    bottom: 20px !important;
  }

  .about-ar5-carousel .owl-prev {
    left: -45px;
  }

  .about-ar5-carousel .owl-next {
    right: -45px;
  }

  .about-article-4 #myTab {
    left: -120px;
  }

  /* themes */
  .themes-article-2 figure figcaption h5 {
    font-size: 16px;
  }

  .themes-article-2 figure figcaption {
    width: 80%;
    padding: 15px 20px;
  }

  .article-row-2 figure img {

    height: 300px;

  }

  .bannner {
    height: 360px !important;
  }

  .about-article-6 .gallery-item img {
    height: 380px !important;
  }

  .figcap h5 {
    font-size: 16px !important;
  }


}



@media only screen and (min-width: 768px) and (max-width: 991px) {

  .header-1-inner {
    grid-gap: 10px;
  }

  .mobile-view .navbar-collapse{
    height: 320px;
    overflow-y: scroll;
  }


  .footer-top {
    padding-top: 200px;
  }

  .header-1-icon {
    width: 45px;
    height: 45px;
  }

  .header-1-icon img {
    width: 18px;
  }

  .header-1-content h5 {
    font-size: 16px;
  }

  .header-1-content p {
    margin: 0;
    font-size: 12px;
  }

  .mobile-logo img {
    width: 200px;
    margin: 20px auto;
    display: flex;
    display: none !important;
  }

  nav.navbar.navbar-expand-lg.navbar-light {
    padding: 10px 0;
    justify-content: end;
   
  }

  .article-row-1 .carousel-caption {
    width: 70%;
  }

  .article-row-1 .carousel-caption h5 {
    font-size: 40px;
    line-height: 40px;
    margin-bottom: 15px;
  }

  .article-row-1 .carousel-caption p {
    color: #fff;
  }


  .section-title h6 {
    font-size: 16px;
  }



  .section-title img {
    width: 200px;
  }

  .article-row-2 .ar2-right-top-image {
    width: 200px;
  }

  .article-row-2 .ar2-left-bottom-image {
    width: 84px;
  }

  .article-row-3.pt-60.pb-60 {
    padding: 40px 0;
  }

  .article-row-3 .about-image-1 {
    width: calc(100% - 100px);
  }

  .article-row-3 .about-image-2 {
    bottom: -100px;
    width: 160px;
  }

  .article-row-3 .about-experience {
    padding: 10px 20px;
    right: 10px;
    top: 10%;
    width: 45%;
  }

  .article-row-3 .about-experience img {
    width: 30px;
  }

  .article-row-3 .about-experience h5 span {
    padding-top: 5px;
    font-size: 16px;
  }


  p.pb-30 {
    padding-bottom: 10px;
  }

  .article-row-4 .ar4-right-image .why-choose-image-1 {
    width: calc(100% - 100px);
  }

  .article-row-4 .ar4-right-image .why-choose-image-2 {
    width: 200px;
  }

  .article-row-4 .ar4-left-content ul li {
    padding-bottom: 5px;
    font-size: 16px;
  }



  .article-row-4 .ar4-bottom-left-image {
    width: 200px;
  }


  .footer-top-content>.row {
    justify-content: center;
    row-gap: 60px;
  }

  .footer-row-1.position-relative.pt-150.pb-60 {
    padding-top: 300px;
  }

  .article-row-8 .testimonial-item {
    height: 100%;
  }

  /* about-us */
  .about-article-2 .about-image-1 {
    width: calc(100% - 80px);
    margin-right: auto;
  }

  .about-article-2 .about-experience {
    padding: 10px 20px;
    width: 50%;
    top: 20px;
  }

  .about-article-2 .about-experience img {
    width: 30px;
  }

  .about-article-2 .about-experience h5 span {
    font-size: 16px;
  }

  .about-article-2 .about-image-video-section {
    bottom: 20px;
  }

  .about-article-2 .about-image-video-section img {
    width: 160px;
  }

  .about-article-2 .about-image-video-section .ar7-vdo-btn {
    top: 45%;
  }

  .about-article-4 .tab_clrs {
    padding: 15px 15px !important;

  }


  .about-article-4 .tab_clrs img {
    width: 30px !important;
  }

  .about-article-4 #myTab .nav-item {
    padding: 10px 0;
  }

  .about-article-4 #myTab {
    top: 30%;
    left: -80px;
  }

  .about-article-4 .about-ar4-right-content {
    padding: 20px 0 20px 70px;
  }

  .about-article-4 .about-ar4-design-section img {
    width: 200px !important;
    bottom: 10px !important;
  }

  .about-article-5 .testimonial-item {
    height: 100%;
  }

  .breadcrumb-article-1 {
    padding: 60px 0;
  }

  .breadcrumb-article-2 {
    padding: 60px 0;
  }





  /* themes */
  .themes-article-2 figure figcaption {
    width: 80%;
    padding: 25px 15px;
  }

  .themes-article-2 figure figcaption h5 {
    font-size: 13px;
  }

  .themes-article-2 figure figcaption a {
    font-size: 13px;
    padding: 8px 15px;
  }

  .dropdown {
    justify-content: flex-start;
  }

  .er {
    width: 200px !important;
  }

  .themedetailssecondimg {
    height: 390px;
  }

  .article-row-2 figure img {

    height: 460px;

  }

  .themes-article-2 figure img {
    height: 200px;
  }

  .bannner {
    height: 320px !important;
  }

  .about-article-6 .gallery-item img {
    height: 286px !important;
  }

  .figcap {
    width: 75%;
    padding: 20px 30px;
  }
  
  .about-article-6 figcaption .style12 {
    font-size: 15px !important;
    padding: 9px 19px !important;
  }
}

.navbar-expand-lg>.container,
.navbar-expand-lg>.container-fluid {
  padding-right: 8px;
  padding-left: 1px;
}

@media only screen and (min-width: 480px) and (max-width: 767px) {

  nav.navbar.navbar-expand-lg.navbar-light {

    justify-content: end;
  }

  .mobile-view .navbar-collapse{
    height: 280px;
    overflow-y: scroll;
  }

 .header-1-icon img {
    width: 20px;
  }

  .header-1-icon {
    padding: 12px;
    width: 50px;
    height: 50px;
  }

  .header-1-content h5 {
    font-size: 16px;
    margin-bottom: 5px;
  }

  .header-row-1 .hr1-left,
  .header-row-1 .hr1-right {
    margin-bottom: 20px;
  }

  .hr1-right {
    text-align: left !important;
    flex-direction: row-reverse;
  }

  .mobile-logo img {
    width: 200px;
    margin: 10px auto;
    display: flex;
  }

  .dropdown {
    justify-content: flex-start;
  }

  .article-row-1 .carousel-caption h5 {
    margin-bottom: 10px;
    line-height: 40px;
  }



  .article-row-1 .carousel-caption {
    width: 60%;
  }

  .carousel-control-next svg,
  .carousel-control-prev svg {
    padding: 15px;
    font-size: 15px;
  }

  .article-row-2.pt-60.pb-60 {
    padding: 30px 0 50px;
  }

  .section-title h3 {
    margin: 0;
  }

  .article-row-3 .small-size-title-image {
    width: 120px !important;
  }



  .article-row-2 .ar2-right-top-image {
    width: 150px;
  }

  .section-title img {
    width: 180px;
  }

  .article-row-2 figure figcaption {
    width: 80%;
    padding: 20px 30px;
  }





  .article-row-2 figure figcaption a {
    font-size: 18px;
    width: 50px;
    height: 50px;
  }

  .article-row-2 .ar2-left-bottom-image {
    width: 80px;
  }

  p.pb-30 {
    padding-bottom: 10px;
  }

  .ar3-right-content {
    padding-top: 70px;
  }

  .article-row-3.pt-60.pb-60 {
    padding: 30px 0;
  }

  .ar4-left-content {
    padding-bottom: 30px;
  }

  .article-row-4.pt-60.pb-100 {
    padding: 30px 0;
  }

  .article-row-4 .ar4-bottom-left-image {
    width: 150px;
  }

  .article-row-5.pt-60.pb-60 {
    padding: 30px 0;
  }

  .article-row-6.pt-60.pb-60 {
    padding: 30px 0;
  }

  .article-row-7.ar7-bg-overlay.pt-60.pb-180 {
    padding: 30px 0 140px;
  }

  .article-row-8.pt-60.pb-160 {
    padding: 30px 0 100px;
  }

  .testimonial-slider .img-part img {
    width: 100px !important;
    margin: auto;
    padding-bottom: 20px;
  }


  .icon-part {
    padding: 10px;
    width: 60px;
    height: 60px;
  }

  .footer-top-content>.row {
    grid-gap: 50px;
  }

  .footer-top-inner h3 {
    margin-top: 10px;
  }

  .footer-row-1.position-relative.pt-150.pb-60 {
    padding-top: 340px;
    padding-bottom: 30px;
  }

  .footer-img img {
    width: 40%;
    display: flex;
    margin: auto !important;


  }

  .footer-row-1 .footer-link li {
    display: inline;
    padding: 10px 15px;
  }

  .footer-row-1 .footer-social-link li a {
    font-size: 12px;
    width: 30px;
    height: 30px;
  }

  .footer-social-link.mt-5 {
    margin-top: 20px !important;
  }

  .footer-right.text-lg-right.text-md-right {
    text-align: right;
  }

  .article-row-8 .testimonial-item {
    height: 100%;
  }

  /* about-us */
  .breadcrumb-article-1 {
    padding: 60px 0;
  }

  .breadcrumb-article-2 {
    padding: 60px 0;
  }

  .breadcrumb-article-1 h2 {
    font-size: 45px;
  }

  .breadcrumb-article-2 h2 {
    font-size: 45px;
  }

  .about-article-2 {
    padding: 40px 0;
  }


  .about-article-4 {
    padding: 40px 0;
  }

  .about-article-4 #myTab {
    transform: none;
    position: unset;
  }

  .about-article-4 #myTab .nav-item {
    padding: 30px 30px 0 30px;
    width: 50%;
    margin: auto;
  }

  .about-article-4 .about-ar4-right-content {
    padding: 0px;
    flex-direction: column;
  }



  .about-article-4 .about-ar4-design-section img {
    width: 130px !important;
    bottom: 0px;
  }

  .about-article-4 ul#myTab .nav-item .nav-link {
    width: 50%;
  }

  .about-article-4 {
    width: 50% !important;
    margin: auto;
  }


  .about-article-5 {
    padding: 40px 0;
  }


  /* services */



  .service-article-2 figure figcaption {
    bottom: 20px;
    padding: 20px 30px;
  }



  /* themes */
  .themes-article-2 {
    padding-top: 40px;
    padding-bottom: 100px;
  }


  /* themes-details */
  .themes-details-article-2 {
    padding-top: 30px;
    padding-bottom: 100px;
  }

  .themes-details-article-2 .ar2-figure figcaption {
    right: 30px;
    padding: 10px 20px;
    bottom: -40px;
    font-size: 25px;
  }

  .themes-details-article-2 .ar2-figure figcaption span {
    font-size: 15px;
  }



  .themes-details-article-2 .share-icon-section a span {
    display: none;
  }

  .themes-details-article-2 .share-icon-section a i {
    padding: 0;
  }

  .themes-details-article-2 .share-icon-section {
    padding: 10px 0px;
    grid-gap: 15px;
  }

  .themes-details-article-2 .pt-50 {
    padding-top: 20px;
  }

  .mobile-header a {

    color: #3d3d3d !important;
  }

  .mobile-header a i {
    color: #d19c65;
    padding-right: 5px;

  }




  .navbar .navbar-brand {
    font-size: 16px;
    color: #541f19;
    display: block;
  }

  .er {
    width: 180px !important;
  }


  .figcap {
    width: 75%;
    padding: 10px 30px;
  }


  .about-article-6 figcaption .style12 {
    font-size: 11px !important;
    padding: 4px 18px !important;
  }

  .footer-top {
    padding-top: 230px;
  }
}

@media only screen and (max-width: 479px) {
  .footer-top {
    padding-top: 180px;
  }


  .footer .footer-top-content .footer-top-inner a {
    word-break: break-word;
  }

  nav.navbar.navbar-expand-lg.navbar-light {
    /* padding: 10px 0; */
    justify-content: end;
  }

  .header-1-icon img {
    width: 15px;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    overflow-y: auto;
    height: 300px;
  }

  .article-row-1 .carousel-item {
    height: 250px;
  }

 .header-1-icon {
    padding: 12px;
    width: 40px;
    height: 40px;
  }

  .header-1-content h5 {
    font-size: 15px;
    margin: 0;
  }

  .header-row-1 .hr1-left,
  .header-row-1 .hr1-right {
    margin-bottom: 20px;
  }

  .hr1-right {
    text-align: left;
    flex-direction: row-reverse;
  }

  .mobile-logo img {
    width: 200px;
    margin: 10px auto;
    display: flex;
  }
  
  .dropdown {
    justify-content: flex-start;
  }

  .article-row-1 .carousel-caption {
    width: 100%;
  }

  .carousel-control-next svg,
  .carousel-control-prev svg {
    padding: 10px;
    font-size: 12px;
  }

  .article-row-2.pt-60.pb-60 {
    padding: 30px 0px !important;
  }
  
  .article-row-3 .small-size-title-image {
    width: 120px !important;
  }

  .article-row-2 .ar2-right-top-image {
    width: 100px;
  }

  .section-title img {
    width: 150px !important;
  }

  .article-row-2 figure figcaption {
    width: 90%;
    padding: 15px 20px;
  }



  .article-row-2 figure figcaption a {

    width: 40px;
    height: 40px;
  }

  .article-row-2 .ar2-left-bottom-image {
    width: 50px;
  }

  .article-row-3 .about-image-1 {
    width: calc(100% - 120px);
  }

  .article-row-3.pt-60.pb-60 {
    padding: 30px 0;
  }

  .article-row-3 .about-image-2 {
    width: 140px;
  }

  .article-row-3 .about-experience {
    padding: 10px 20px;
    right: 30px;
    top: 35%;
    grid-gap: 10px;
    width: 50%;
  }

  .article-row-3 .about-experience img {
    width: 30px;
  }


  .ar3-right-content {
    padding-top: 70px;
  }



  .article-row-4.pt-60.pb-100 {
    padding: 30px 0 50px;
  }


  ul.pt-20 {
    padding: 0;
  }

  .article-row-4 .ar4-right-image .why-choose-image-1 {
    width: calc(100% - 100px);
  }

  .article-row-4 .ar4-right-image .why-choose-image-2 {
    width: 150px;
  }

  .article-row-4 .ar4-bottom-left-image {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: -1;
    width: 120px;
  }

  .article-row-5.pt-60.pb-60 {
    padding: 30px 0;
  }

  .article-row-5 .ar5-inner-card {
    margin: 0 20px;
  }

  .article-row-6.pt-60.pb-60 {
    padding: 30px 0;
  }



  .article-row-7.ar7-bg-overlay.pt-60.pb-180 {
    padding: 30px 0px 140px;
  }

  .article-row-8.pt-60.pb-160 {
    padding: 30px 0 100px;
  }



  .icon-part img {
    width: 15px;
  }

  .icon-part {
    padding: 10px;
    width: 50px;
    height: 50px;
  }

  .footer-top-content>.row {
    grid-gap: 50px;
  }

  .footer-top-inner h3 {

    margin-top: 10px;
  }

  .footer-row-1.position-relative.pt-150.pb-60 {
    padding-top: 300px;
    padding-bottom: 30px;
  }

  .footer-img img {
    width: 50%;
    display: flex;
    margin: auto;






  }

  .footer-row-1 .footer-link li {
    display: block;
    padding: 10px 15px;
  }

  .footer-row-1 .footer-social-link li a {

    width: 30px;
    height: 30px;
  }

  .footer-social-link.mt-5 {
    margin-top: 20px !important;
  }

  .footer-right.text-lg-right.text-md-right {
    text-align: right;
  }

  .article-row-8 .testimonial-item {
    height: 100%;
  }

  /* about-us */
  .breadcrumb-article-1 {
    padding: 40px 0;
  }

  .breadcrumb-article-2 {
    padding: 40px 0;
  }


  .about-article-2 .about-image-1 {
    width: calc(100% - 100px);
  }

  .about-article-2 .about-image-video-section img {
    width: 140px;
  }

  .about-article-2 .about-image-video-section {
    bottom: 20px;
    right: 10px;
  }

  .about-article-2 .about-image-video-section img {
    width: 130px;
  }

  .about-article-2 .about-experience {
    padding: 10px 20px;
    right: 10px;
    top: 20px;
    grid-gap: 10px;
    width: 54%;
  }

  .about-article-2 .about-experience img {
    width: 30px;
  }



  .about-article-2 .ar7-vdo-btn .video {
    height: 50px !important;
    width: 50px !important;
    line-height: 50px !important;
    font-size: 15px !important;
  }

  .about-article-2 .ar7-vdo-btn .waves {
    width: 150px !important;
    height: 150px !important;
  }

  .about-article-2 .about-image-video-section .ar7-vdo-btn {
    top: 48%;
  }


  .about-article-4 #myTab {
    transform: none;
    align-items: center;
    position: unset;
  }

  .about-article-4 .tab_clrs {
    padding: 20px 5px 0 5px !important;
    width: 38% !important;
  }

  .about-article-4 ul#myTab {
    flex-direction: row !important;
  }


  .about-article-4 .tab_clrs {
    grid-gap: 10px !important;
    padding: 5px !important;

    width: 50%;
    margin: auto;
  }

  .about-article-4 #myTab .nav-item .nav-link img {
    width: 30px !important;
    height: 25px !important;
  }

  .about-article-4 .about-ar4-right-content {
    padding: 0px;
    flex-direction: column;
  }



  .about-article-4 .about-ar4-design-section img {
    width: 150px !important;
    bottom: 0px !important;
  }



  .about-article-5 .testimonial-item {
    height: 100%;
  }


  /* services */



  .service-article-2 figure figcaption {
    /* bottom: -25px; */
    padding: 15px 20px;
  }

  .service-article-2 figure.ar2-figure {
    margin-bottom: 30px;
  }



  .service-details-article-2 .service-details-last {
    padding: 0;
  }

  /* themes */
  .themes-article-2 figure figcaption {
    width: 80% !important;
    padding: 12px 3px !important;
    height: auto;
  }

  .themes-article-2 figure figcaption a {
    font-size: 11px !important;
    padding: 2px 10px !important;
  }

  .themes-article-2 {
    padding-top: 30px;
    padding-bottom: 100px;
  }

  .themes-article-2 figure.ar2-figure {
    margin-bottom: 80px;
  }

  /* themes-details */
  .themes-details-article-2 {
    padding-top: 30px;
    padding-bottom: 100px;
  }

  .themes-details-article-2 .ar2-figure figcaption {
    right: 10px;
    padding: 5px 10px;
    bottom: -30px;
    font-size: 20px;
  }

  .themes-details-article-2 .ar2-figure figcaption span {
    font-size: 14px;
  }

  .themes-details-article-2 .share-icon-section a span {
    display: none;
  }

  .themes-details-article-2 .share-icon-section a i {
    padding: 0;
  }

  .themes-details-article-2 .share-icon-section {
    padding: 10px 0px;
    grid-gap: 10px;
  }

  .themes-details-article-2 .pt-50 {
    padding-top: 20px;
  }

  .mobile-header a {
    color: #3d3d3d !important;
  }

  .mobile-header a i {
    color: #d19c65;
    padding-right: 5px;
  }

  .about-article-3 .four.col-lg-3.col-md-6.col-sm-6.mb-30 {
    max-width: 50%;
  }

  .about-article-3 .counter-box {
    padding: 15px 10px 15px;
  }

  .about-article-3 .counter-box img {
    width: 40px;
  }

  .navbar .navbar-brand {
    font-size: 14px;
    color: #541f19;
    display: block;
  }

  .er {
    width: 150px !important;
  }

  .themedetailssecondimg {
    height: 200px;
  }

  .bannner {
    height: 100% !important;

  }



  .style12 {
    font-size: 12px;
    padding: 5px 15px;

  }

  .figcap {
    width: 82%;
    /* height: 83px; */
    padding: 20px 30px;
  }

  .about-article-6 figcaption .style12 {
    font-size: 12px !important;
    padding: 6px 8px !important;

  }

  .about-article-6 .figcap {
    padding: 10px 10px;
  }


}

@media only screen and (max-width: 319px) {

.article-row-1 .carousel-caption h5 {
  font-size: 25px;
  line-height: 27px;
}

 .section-title h3 {
  font-size: 24px !important;
 
}
.about-article-4 .tab_clrs {
  
  width: 45% !important;
}

  
  .about-article-6 figcaption .style12 {
   
    padding: 7px 15px !important;
  }

  .contact-article-2 .contact-ar2-right .g-recaptcha {
    transform: scale(0.68) !important;
    transform-origin: 0 0;
  }

  .mobile-header .mobile-text-left{
    padding: 0px 4px;
  }

  .mobile-header .mobile-text-right{
    padding: 0px 4px;
  }
}

@media only screen and (max-width: 767px) {
  .header-row-1 {
    display: none;
  }

  .article-row-3 .left_align .section-title {
    display: block;
  }

  .article-row-3 .right_align .section-title {
    display: none;
  }

  .article-row-4 .left_align .ar4-right-image {
    display: block;
    padding-bottom: 15px;
  }

  .article-row-4 .right_align .ar4-right-image {
    display: none;
  }

  .about-article-2 .right_align .about-ar3-right-image {
    display: none;
  }

  .about-article-2 .left_align .about-ar3-right-image {
    display: block;
    padding-bottom: 15px;
  }

  .about-ar4-right-content .section-title {
    padding-top: 25px;
  }

  .themes-article-2 .grid-dividing:nth-last-child(2) {
    margin-bottom: 20px;
  }

  .service-details-article-2 .service-sidebar .widget {
    margin-bottom: 0px;
  }

  .mobile-header {
    display: block;
  }

  .about-article-5 .slider-content .title h4 {
    margin: auto;
  }

  .about-article-5 .slider-content .title p {
    margin: auto;
  }

  .about-article-5 .slider-content .title {
    width: 100%;
  }

  .about-article-5 .testimonial-item .review {
    justify-content: center;
    margin-top: 5px;
  }

  .article-row-8 .slider-content .title h4 {
    margin: auto;
  }

  .article-row-8 .slider-content .title p {
    margin: auto;
  }

  .article-row-8 .testimonial-item .review {
    justify-content: center;
    margin-top: 5px;
  }

}

@media only screen and (min-width: 768px) {
  .header-row-1 {
    display: block;
  }

  .mobile-header {
    display: none;
  }

}


@media only screen and (max-width: 991px) {
  .navbar .navbar-brand {
    display: block;
  }

  .navbar-expand-lg>.container,
.navbar-expand-lg>.container-fluid {
  padding-right: 8px;
  padding-left: 8px;
}

  .mobile-view .services_mobile_view {
    padding: 10px 10px !important;
  }
 
  .article-row-1 .carousel-control-prev,.article-row-1 .carousel-control-next{
    display: none;
  }

  

  .mobile-view .nav-toggle-btn>a {
    display: block;
    color: #fff !important;
  }

  .gallery-article-2 .tab-content .grid_dividing:nth-last-child(2) {
    margin-bottom: 24px;
  }

  .gallery-article-2 .tab-content .grid_dividing:nth-last-child(3) {
    margin-bottom: 24px;
  }

  .about-article-5 .owl-carousel .testimonial-slider .testimonial-item img {
    width: fit-content !important;
    margin: auto;
    padding-bottom: 20px;
  }

  .article-row-8 .owl-carousel .testimonial-slider .testimonial-item img {
    width: fit-content !important;
    margin: auto;
    padding-bottom: 20px;
  }

  .themes-details-article-2 .ar2-figure figcaption {

    right: 10px;
    bottom: 0px;
  }

  .mobile-view .mobile-view-main button {
    position: absolute;
    right: 20%;
    top: 50%;
    transform: translateY(-50%);
    border: 2px solid #531f18;
  }

  .mobile-view {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .mobile-view-main button {
    background-color: #d19c65;
  }


  .mobile-view .mobile-view-main {
    display: flex;
    justify-content: space-between;
  }

  .header-row-2 .container {
    align-items: start !important;
  }


  /* .dropdown_menu .dropdown_inner_one .dropdown-item:hover  .text{
    color: #fff;
    } */

  .mobile-view {
    display: block;
  }

  .webview {
    display: none;

  }


  .dropdown_inner_two {
    color: #000 !important;
  }

  .dropdown_menu .dropdown_inner_one .dropdown-item:hover {
    background-color: #fff;
    border: 1px solid #fff;
  }


  .dropdown:hover .dropdown_menu {

    border: 2px solid #fff;
  }

  /* .dropdown_inner_two{
    background-color: #531f18;
  }

  .dropdown_inner_two a .text-one{
    color: #fff !important;
  } */


  .dropdown_menu {
    overflow-y: scroll;
    height: 500px;
  }



  .header-social-link {
    display: none;
  }

  .header-row-2 {
    overflow-y: auto;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    overflow-y: auto;
    height: 300px;
  }

  .about-article-3 .col-dividing:nth-child(1) {
    margin-bottom: 30px;
  }

  .about-article-3 .col-dividing:nth-child(2) {
    margin-bottom: 25px;
  }

}






@media screen and (min-width:992px) {
  .dropdown_inner_two {
    position: absolute;
    left: 99%;
    top: 0%;
  }

  .navbar .navbar-brand {
    display: none;
  }

  .dropdown-toggle {
    padding: 0 !important;
  }

  .dropdown_menu .dropdown_inner_one .dropdown-item:hover {
    background-color: #d19c65;

  }

  .dropdown_menu .dropdown_inner_one .dropdown-item:hover .text {
    color: #fff !important;

  }
}

@media screen and (max-width:375px) {
  .contact-article-2 .contact-ar2-right .g-recaptcha {
    transform: scale(0.77);
    transform-origin: 0 0;
  }
}

@media screen and  (max-width:640px) {
  p {
    font-size: 14px;
  }
  .btn{
    font-size: 14px;
  }

  .themes-details-article-2  p h3 {
    font-size: 16px;
}

  .themes-details-article-2  h3{
    font-size: 26px;
  }

  .about-article-4 .about-ar4-right-content p small big {
    font-size: 14px;
  }

  .themes-details-article-2 p h3 {
    font-size: 18px;
  }


  .article-row-3 .about-experience h5 span {
    font-size: 18px;
  }

  .article-row-3 .about-experience h5 {
    font-size: 22px;
  }

  .article-row-2 figure figcaption h5 {
    font-size: 18px;

  }


  .article-row-5 .section-title span {
    font-size: 14px;
  }

  .article-row-1 .carousel-caption p {
    font-size: 16px;
  }

  .gallery-article-2 .nav-tabs .nav-link {
    font-size: 16px;
  }

  .service-details-article-2 .widget .widget-title {
    font-size: 18px;
  }

  .breadcrumb-article-1_sub_title {
    font-size: 16px;
  }



  .contact-article-2 h5 {
    font-size: 18px;
  }

  .contact-article-2 .contact-ar2-left figure figcaption h6 {
    font-size: 16px;
  }

  .article-row-3 p {
    font-size: 14px;
  }

  .article-row-4 .ar4-left-content ul li {
    font-size: 18px;
  }

  .section-title h6 {
    font-size: 16px;
  }

  .themes-article-2 figure figcaption h5 {
    font-size: 18px;
  }

  .service-article-2 figure figcaption h5 {
    font-size: 18px;
  }

  .footer-top-inner h3 {
    font-size: 18px;
  }

  .article-row-8 .testimonial-item .title h4 {
    font-size: 18px;
  }

  .section-title h3 {
    font-size: 26px;
    margin: 0;
  }

  .breadcrumb-article-1 h2 {
    font-size: 30px;
  }

  .breadcrumb-article-2 h2 {
    font-size: 30px;
  }

  .breadcrumb-article-1 .breadcrumb-inner a {
    font-size: 16px;
  }

  .breadcrumb-article-2 .breadcrumb-inner a {
    font-size: 16px;
  }

  .breadcrumb-item.active {
    font-size: 16px;
  }

  .service-details-article-2 .category-list a {
    font-size: 14px;
  }

  .mobile-header a {
    font-size: 14px;
    color: #3d3d3d !important;
  }

  .footer-row-2 .footer-left p {
    font-size: 14px;
  }

  .footer-row-2 .footer-right p {
    font-size: 14px;
  }

  .footer-row-1 .footer-link li {

    font-size: 14px;
  }

  .about-article-3 .counter {
    font-size: 22px;
  }

  .article-row-5 .ar5-inner-card h4 {
    font-size: 18px;
  }

  .ar7-inner-section p {
    font-size: 14px;
  }

  .about-article-3 .counter-box p {
    font-size: 18px;
  }

  .about-article-5 .testimonial-item .title h4 {
    font-size: 18px;
  }

  .about-article-6 .figcap figcaption h5 {
    font-size: 18px;
  }

  .about-article-4 .about-ar4-right-content .tab-content .section-title p small big {
    font-size: 14px !important;
  }

  .about-article-4 .about-ar4-right-content .tab-content .section-title p {
    font-size: 14px !important;
  }

  .about-article-4 #myTab .nav-item .nav-link {
    font-size: 16px;
  }

  .article-row-1 .carousel-caption h5 {
    font-size: 30px;
    line-height: 30px;
  }

  .about-article-2 .about-experience h5 span {
    font-size: 18px;
  }

  .about-article-2 .about-experience h5 {
    font-size: 22px;
  }
}

@media screen and (max-width:460px) {
  .footer-top-content {
    top: -120px;
  }
}

@media screen and (max-width:1199px) {
  .article-row-3 {
    padding-bottom: 25px;
  }
}



@media screen and (width:1200px) {
  .article-row-3 {
    padding-bottom: 115px;
  }
}

@media screen and (max-width:460px) {
  .footer {
    margin-top: 152px;
  }
}

@media screen and (max-width:575px) {
  .mobile-header .mobile-text-right {
    text-align: left;
  }

  .about-article-3 .col-dividing:nth-child(3) {
    margin-bottom: 25px;
  }

  .about-article-3 .counter-box {
    height: 100%;
  }

  .mobile-view .navbar-collapse{
    height: 400px;
    overflow-y: scroll;
  }
}

@media screen and (min-width:576px) and (max-width:767px) {
  .mobile-header .mobile-text-right {
    text-align: right;
  }

  .about-article-3 .counter-box {
    height: 232px;
  }
}

@media screen and (min-width:768px) and (max-width:991px) {

  .about-article-3 .counter-box {
    height: 236px;
  }
}