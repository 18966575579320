@import url(../css/fonts.css);
html {
  scroll-behavior: smooth;
  height: 100%;
}
body {
  margin: 0;
  padding: 0;
  font-family: "Fira Sans", sans-serif !important;
  font-size: 16px;
  font-weight: 400;
  color: #1c1c1c;
}
a:active,
a:hover {
  outline: 0;
}
a {
  color: #3d3d3d;
  text-decoration: none !important;
  -webkit-transition: color 0.3s ease;
  transition: color 0.3s ease;
}
a:hover {
  color: #531f18;
  text-decoration: none;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
input:focus-visible {
  outline: none;
}
ul {
  margin: 0;
  padding: 0;
  list-style: none;
  padding-left: 0 IMP !important;
}


li {
  list-style: none;
}

.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: none;
}

.btn {
  display: inline-block !important;
  line-height: 26px !important;
  font-weight: 500 !important;
  text-align: center !important;
  -webkit-transition: all 0.7s ease-in-out !important;
  transition: all 0.7s ease-in-out;
  position: relative !important;
  z-index: 1 !important;
  border: none !important;
  padding: 11px 30px 11px !important;
  border-radius: 0 !important;
  color: #fff !important;
  border: 1px solid transparent !important;
  overflow: hidden !important;
  white-space: nowrap;
  margin: 0 !important;
}

.btn.style1 {
  background-color: #d19c65 !important;
}

.btn:before {
  position: absolute !important;
  top: 0;
  left: 0;
  content: "";
  width: 50%;
  height: 0;
  visibility: hidden;
  opacity: 0;
  z-index: -1;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.btn:after {
  position: absolute;
  bottom: 0;
  right: 0;
  content: "";
  width: 50%;
  height: 0;
  visibility: hidden;
  opacity: 0;
  z-index: -1;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.btn:hover {
  color: #d19c65 !important;
  border-color: rgba(0, 0, 0, 0.09) !important;
}

.btn.style1:after,
.btn.style1:before {
  background: #ecf0f2 !important;
}

.btn:hover:after,
.btn:hover:before {
  width: 100%;
  height: 100%;
  visibility: visible;
  opacity: 1;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #1c1c1c;

  font-weight: 600;
}




p {
  font-size: 16px;
  color: #3d3d3d;

}

.scrolltop {
  display: none;
  width: 100%;
  margin: 0 auto;
  position: fixed;
  bottom: 20px;
  right: 10px;
  z-index: 9999;
}

.scroll {
  position: absolute;
  right: 20px;
  bottom: 20px;
  background: rgb(209 156 101);

  text-align: center;
  margin: 0 0 0 0;
  z-index: 99999999999999;
  cursor: pointer;
  transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  color: #531f18;
}

.scroll:hover {
  background: #531f18;
  color: rgb(209 156 101);
  transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
}

.scroll:hover .fa {
  padding-top: -10px;
}

.scroll svg {
  font-size: 18px;
  margin-top: 0px;
  margin-left: 0px;
  transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
}

.head {
  position: sticky;
  top: 0;
  left: 0;
}

.sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  background: #d19c65;
}

.section-title img {
  width: 250px;
}

.section-title h6 {
  color: #531f18;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
}

.section-title h3 {
  font-size: 40px;
  font-weight: 700;
  color: #1c1c1c;
  text-transform: capitalize;
}

.title-white h6 {
  color: #fff;
}

.title-white h3 {
  color: #fff;
}

.header-1-inner {
  display: flex;
  align-items: center;
  grid-gap: 15px;
}

.hr1-left {
  justify-content: flex-start;
}

.hr1-right {
  justify-content: flex-end;
  text-align: right;
}

.header-1-icon {
  background: #d19c65;
  padding: 15px;
  border-radius: 50%;
  width: 55px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;

}

.header-1-icon img {
  width: 20px;

}

.header-1-content h5 {
  font-size: 20px;
}

.header-1-content p {
  margin: 0;
  font-size: 14px;
}

.header-row-2 {
  background-color: #d19c65;
  position: relative;
  z-index: 2;

}

nav.navbar.navbar-expand-lg.navbar-light {
  padding: 0;
}

.navbar .nav-item .nav-link:after {
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 0%;
  height: 3px;
  background-color: #531f18;
  transform: translateX(-50%);
  -webkit-transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  -o-transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  border-top: 0;
  border-right: 0;
  border-bottom: 0;
  border-left: 0;
}

.navbar .nav-item.active .nav-link:after {
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 0%;
  height: 3px;
  background-color: #531f18;
  transform: translateX(-50%);
  -webkit-transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  -o-transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.navbar .nav-item.active .nav-link:after {
  width: 100%;
}

.navbar .nav-item .nav-link:hover:after {
  width: 100%;
}

.navbar .nav-item .nav-link:hover {
  color: #531f18 !important;
  font-weight: 500 !important;
}

.navbar .nav-item .nav-link:after {
  content: "";
  display: block;
}

.navbar .nav-item .nav-link {
  position: relative;
  display: inline-block;
  padding: 10px 10px;
  color: #fff !important;
  font-size: 16px;
}

li.nav-item {
  padding: 10px 30px;
}

.navbar .nav-item.active .nav-link {
  color: #531f18;
  font-weight: 500;
}

.dropdown {
  /* padding: 10px 30px; */
  display: flex;
  justify-content: center;
  align-items: flex-start;
  color: #818181;
  position: relative !important;
  font-size: 14px;
  /* perspective: 1000px; */
  /* z-index: 100; */
  flex-direction: column;
}

.navbar-light .navbar-nav .nav-link:focus {
  color: #531f18;
}

.navbars {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}


.dropdown:hover .dropdown_menu {
  display: block !important;
}

.dropdown-menu {
  --bs-dropdown-padding-y: 0rem !important;
}

.servise_btn:hover .dropdown-menu {
  display: block;

}

.dropdown-toggle::after {
  display: inline-block !important;

  content: "" !important;

}

.background_clr {
  background-color: #d19c65 !important;
}

.dropdown_menu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 230px;
  perspective: 1000px;
  z-index: -1;
  padding: 0;
  display: none;
  opacity: 0;
  background-color: #ffffff;
}

.dropdown_menu li a:hover {
  background-color: #d19c65;
  color: #fff;
}

.stage {
  text-decoration: none;
}

.dropdown_menu li a {
  font-size: 14px;
  padding: 8px 20px;
  display: block;

  text-decoration: none;
  color: #898989;
}

.dropdown:hover .dropdown_menu--animated {
  display: block;
  opacity: 1;

}

.dropdown_menu--animated {
  display: none;
}


.dropdown_menu-7 {
  animation: rotateMenu 400ms ease-in-out forwards;
  transform-origin: top center;
}

@-moz-keyframes rotateMenu {
  0% {
    transform: rotateX(-90deg);
  }

  70% {
    transform: rotateX(20deg);
  }

  100% {
    transform: rotateX(0deg);
  }
}

@-webkit-keyframes rotateMenu {
  0% {
    transform: rotateX(-90deg);
  }

  70% {
    transform: rotateX(20deg);
  }

  100% {
    transform: rotateX(0deg);
  }
}

@-o-keyframes rotateMenu {
  0% {
    transform: rotateX(-90deg);
  }

  70% {
    transform: rotateX(20deg);
  }

  100% {
    transform: rotateX(0deg);
  }
}

@keyframes rotateMenu {
  0% {
    transform: rotateX(-90deg);
  }

  70% {
    transform: rotateX(20deg);
  }

  100% {
    transform: rotateX(0deg);
  }
}


.article-row-1 .carousel-caption {
  position: absolute !important;
  left: 50%;
  top: 50%;
  right: inherit;
  bottom: inherit;
  transform: translate(-50%, -50%);
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
  width: 60%;
}

.article-row-1 .carousel-caption h5 {
  font-size: 45px;
  color: #fff;
  line-height: 50px;
  margin-bottom: 25px;
}

.article-row-1 .carousel-caption p {
  font-size: 18px;
  color: #fff;
}

  .article-row-1 .carousel-inner .carousel-item:before {
  position: absolute;
  content: "";
  background: rgba(0, 0, 0, 0.2);
   width: 100%;
  height: 100%; 
  left: 0px;
  top: 0px;
  z-index: 1;
  padding: 100px 0px;
}  

.carousel-control-next,
.carousel-control-prev {
  opacity: 1 !important;
}

.carousel-control-prev i {
  background: #d19c65;
  color: #531f18;
  padding: 15px;
  border-radius: 50%;
  font-size: 20px;
}

.carousel-control-prev i:hover,
.carousel-control-next i:hover {
  background: #fff;
  color: #d19c65;
}

.carousel-control-next i {
  background: #d19c65;
  color: #531f18;
  padding: 15px;
  border-radius: 50%;
  font-size: 20px;
}

.article-row-2 figure.ar2-figure {
  position: relative;
  margin-bottom: 60px;
}

.article-row-2 figure figcaption {
  position: absolute;
  background: #fff;
  bottom: -50px;
  left: 50%;
  transform: translatex(-50%);
  width: 80%;
  padding: 25px 35px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 3px 10px #afafaf;
}

.article-row-2 figure img {
  width: 100%;
  object-fit: cover;
  transition: transform 1000ms;
}

.article-row-2 figure:hover img {
  transform: scale(1.15);
}

.article-row-2 .zoom-img {
  overflow: hidden;
  position: relative;
}

.article-row-2 figure:hover figcaption h5 {
  color: #531f18;
}

.article-row-2 figure figcaption h5 {
  font-size: 20px;
  color: #1c1c1c;
}

.article-row-2 figure figcaption p {
  margin: 0;
}

.article-row-2 figure:hover figcaption .ic {
  color: #ffeedd !important;
  background: #531f18 !important;
}

.article-row-2 figure figcaption .ic {
  font-size: 20px;
  background: #ffeedd;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: #531f18;
}

.ar2-carousel .owl-prev {
  top: 42%;
  left: -70px;
  position: absolute;
  transform: translateY(-50%);
  color: #531f18;
  font-size: 30px !important;
  background: none !important;
  border: 1px solid #d19c65;
  border-radius: 50% !important;
  width: 50px;
  height: 50px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  font-size: 20px !important;
}

.ar2-carousel .owl-next {
  top: 42%;
  right: -70px;
  position: absolute;
  transform: translateY(-50%);
  color: #531f18;
  font-size: 30px !important;
  background: none !important;
  border: 1px solid #d19c65;
  border-radius: 50% !important;
  width: 50px;
  height: 50px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  font-size: 20px !important;
}

.ar2-carousel .owl-prev:hover,
.ar2-carousel .owl-next:hover {
  background: #d19c65 !important;
}

.ar2-carousel .owl-prev:hover i,
.ar2-carousel .owl-next:hover i {
  color: #531f18;
}

.ar2-carousel .owl-nav [class*="owl-"]:hover {
  background: none;
}

.ar2-carousel .owl-nav [class*="owl-"] {
  background: none;
}

.ar2-carousel .owl-carousel {
  position: relative;
}

.ar2-carousel .owl-prev i,
.ar2-carousel .owl-next i {
  color: #d19c65;
}

.article-row-2 {
  position: relative;
}

.article-row-2 .ar2-right-top-image {
  position: absolute;
  right: 0;
  top: 0px;
  width: 250px;
  z-index: -1;
}

.article-row-2 .ar2-left-bottom-image {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 130px;
  z-index: -1;
}

.article-row-3 {
  background-color: #ffeedd;
}

.article-row-3 .small-size-title-image {
  width: 200px !important;
}

.article-row-3 p {
  font-size: 16px;
  text-align: justify;
}

.article-row-3 .theme-btn {
  background: #d19c65;
  color: #531f18;
  padding: 15px 30px;
  font-size: 18px;
  font-weight: 500;
  margin: 20px 0;
}

.article-row-3 .ar3-left-image {
  position: relative;
}

.article-row-3 .about-image-1 {
  width: calc(100% - 200px);
  margin-right: auto;
}

.article-row-3 .about-image-2 {
  position: absolute;
  bottom: -50px;
  right: 0;
  width: 250px;
}

.article-row-3 .about-experience {

  position: absolute;
  background: #fff;
  padding: 20px 30px;
  right: 40px;
  top: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40%;
  grid-gap: 20px;
}


.article-row-3 .about-experience img {
  width: 40px;
}

.article-row-3 .about-experience h5 {
  text-align: left;
  color: #d19c65;
  font-weight: 800;
  font-size: 26px;
  margin: 0;
}

.article-row-3 .about-experience h5 span {
  display: block;
  padding-top: 10px;
  font-size: 20px;
  color: #1c1c1c;
  font-weight: 600;
}

.ar3-carousel .owl-prev {
  top: 42%;
  left: -70px;
  position: absolute;
  transform: translateY(-50%);
  color: #531f18 !important;
  background: #fffefd !important;
  border-radius: 50% !important;
  width: 50px;
  height: 50px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  font-size: 30px !important;
  border: 1px solid #531f18 !important;
}

.ar3-carousel .owl-next {
  top: 42%;
  right: -70px;
  position: absolute;
  transform: translateY(-50%);
  color: #531f18 !important;
  background: #fffefd !important;
  border-radius: 50% !important;
  width: 50px;
  height: 50px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  font-size: 30px !important;
  border: 1px solid #531f18 !important;
}


.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
  background: none;
  color: inherit;

  padding: 0 !important;
  font: inherit;
}

.ar3-carousel .owl-next:hover,
.ar3-carousel .owl-prev:hover {
  background: #531f18 !important;
  color: #ffeedd !important;

}

.ar3-carousel .owl-nav [class*="owl-"]:hover {
  background: none;
}

.ar3-carousel.owl-nav [class*="owl-"] {
  background: none;
}

.ar3-carousel {
  position: relative;
}

.article-row-4 .ar4-left-content ul li {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  padding-bottom: 15px;
  color: #1c1c1c;
  font-size: 20px;
  font-weight: 500;
}

.article-row-4 .ar4-right-image {
  position: relative;
}

.article-row-4 .ar4-right-image .why-choose-image-1 {
  width: calc(100% - 150px);
  margin-right: auto;
}

.article-row-4 .ar4-right-image .why-choose-image-2 {
  position: absolute;
  top: 50%;
  right: 0;
  width: 300px;
  transform: translateY(-50%);
  border-radius: 100%;
}

.article-row-4 {
  position: relative;
}

.article-row-4 .ar4-bottom-left-image {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;
  width: 250px;
}

.article-row-5 {
  background: url(../Components/images/article-5-background.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

.article-row-5 .section-title span {
  display: block;
  padding: 10px;
  font-size: 16px;
  font-weight: 500;
  color: #3d3d3d;
}

.article-row-5 .ar5-inner-card .ar5-icon-image {
  max-width: 60px;
  margin: 10px auto;
}

.article-row-5 .ar5-inner-card {
  text-align: center;
  padding: 20px;
  border: 1px solid #531f18;
  height: 199px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  grid-gap: 20px;
}

.article-row-5 .ar5-inner-card:hover {
  background-color: #fff;
}

.article-row-5 .ar5-inner-card:hover h4 {
  color: #531f18;
}

.article-row-5 .ar5-inner-card h4 {
  font-size: 20px;
  text-transform: uppercase;
}

.ar5-carousel .owl-prev {
  top: 42%;
  left: -70px;
  position: absolute;
  transform: translateY(-50%);
  color: #531f18;
  font-size: 40px !important;
  background: #d19c65 !important;
  border-radius: 50% !important;
  width: 50px;
  height: 50px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  font-size: 20px !important;
}

.ar5-carousel .owl-next {
  top: 42%;
  right: -70px;
  position: absolute;
  transform: translateY(-50%);
  color: #531f18;
  font-size: 40px !important;
  background: #d19c65 !important;
  border-radius: 50% !important;
  width: 50px;
  height: 50px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  font-size: 20px !important;
}

.ar5-carousel .owl-nav [class*="owl-"]:hover {
  background: none;
}

.ar5-carousel.owl-nav [class*="owl-"] {
  background: none;
}

.ar5-carousel {
  position: relative;
}

.ar5-carousel .owl-prev i,
.ar5-carousel .owl-next i {
  color: #531f18;
}

.ar6-carousel .owl-dots .owl-dot span {
  width: 12px;
  height: 12px;
  margin: 5px 7px;
  background: #d19c65 !important;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 200ms ease;
  border-radius: 30px;
}

.ar6-carousel .owl-dots .owl-dot.active span,
.ar6-carousel .owl-dots .owl-dot:hover span {
  background: #531f18 !important;
}


.article-row-6 .gallery-item {
  overflow: hidden;
  position: relative;
}

.article-row-6 .gallery-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 1000ms;
}

.article-row-6 .gallery-item:hover img {
  transform: scale(1.15);
}

.article-row-7 {
  background: url(../Components/images/article-7-background.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;
}

.ar7-bg-overlay:before {
  position: absolute;
  content: "";
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  z-index: 1;
}

.ar7-inner-section {
  position: relative;
  z-index: 1;
}

.ar7-inner-section p {
  color: #fff;
  text-align: center;
  font-size: 16px;
}

.video-main {
  position: relative !important;
  display: inline-block !important;
}

.video {
  height: 70px;
  width: 70px;
  line-height: 70px;
  text-align: center;
  border-radius: 100%;
  background: transparent;
  color: #531f18;
  display: inline-block;
  background: #ffffff;
  z-index: 999;
  font-size: 20px;
  padding-left: 3px;
}

@keyframes waves {
  0% {
    -webkit-transform: scale(0.3, 0.3);
    transform: scale(0.3, 0.3);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }

  50% {
    opacity: 0.8;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
  }

  100% {
    -webkit-transform: scale(0.8, 0.8);
    transform: scale(0.8, 0.8);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
}

.fa-play:before {
  content: "\f04b";
}

.waves {
  position: absolute;
  width: 170px;
  height: 170px;
  background: rgb(255 255 255 / 50%);
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  border-radius: 100%;
  right: -50px;
  bottom: -50px;
  z-index: -1;
  -webkit-animation: waves 3s ease-in-out infinite;
  animation: waves 3s ease-in-out infinite;
}

.wave-1 {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.wave-2 {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.wave-3 {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

.ar7-vdo-btn {
  display: inline-block;
  position: absolute;
  bottom: -120px;
  left: 50%;
  transform: translatex(-50%);
}



.article-row-8 .testimonial-item {
  padding: 30px 10px;
  box-shadow: 0px 0px 10px #b3b3b3;
  -webkit-box-shadow: 0px 0px 10px #b3b3b3;
  margin: 20px;
  border-radius: 5px;
  height:100%;
}


.owl-carousel .owl-item img {
  display: block;
  width:50%;
 
}

.article-row-8 .testimonial-item .title {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  padding: 0 15px;
  margin: 0;
}

.article-row-8 .testimonial-item .title h4 {
  font-size: 20px;
}

.article-row-8 .testimonial-item .title p {
  margin: 0;
}

.article-row-8 .testimonial-item .review {
  display: flex;
  align-items: center;
  justify-content: end;
  grid-gap: 5px;
  color: #fdc109;
}

.article-row-8 .testimonial-item .description {
  padding-top: 20px;
}

.ar8-carousel .owl-prev {
  top: 42%;
  left: -70px;
  position: absolute;
  transform: translateY(-50%);
  color: #531f18;
  font-size: 40px !important;
  background: #ffeedd !important;
  border-radius: 50% !important;
  width: 50px;
  height: 50px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  font-size: 20px !important;
}

.ar8-carousel .owl-next {
  top: 42%;
  right: -70px;
  position: absolute;
  transform: translateY(-50%);
  color: #531f18;
  font-size: 40px !important;
  background: #ffeedd !important;
  border-radius: 50% !important;
  width: 50px;
  height: 50px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  font-size: 20px !important;
}

.ar8-carousel .owl-next:hover,
.ar8-carousel .owl-prev:hover {
  background: #531f18 !important;
  color: #ffeedd !important;
}

.ar8-carousel .owl-next:hover i,
.ar8-carousel .owl-prev:hover i {
  background: #531f18 !important;
  color: #ffeedd !important;
}

.ar8-carousel .owl-nav [class*="owl-"]:hover {
  background: none;
}

.ar8-carousel.owl-nav [class*="owl-"] {
  background: none;
}

.ar8-carousel {
  position: relative;
}

.ar8-carousel .owl-prev i,
.ar8-carousel .owl-next i {
  color: #531f18;
}

.head {
  position: sticky !important;
  top: 0;
  left: 0;
}

.carousel-control-prev svg {
  background: #d19c65;
  color: #531f18;
  padding: 15px;
  border-radius: 50%;
  font-size: 20px;
}

.carousel-control-prev svg:hover,
.carousel-control-next svg:hover {
  background: #fff;
  color: #d19c65;
}

.carousel-control-next svg {
  background: #d19c65;
  color: #531f18;
  padding: 15px;
  border-radius: 50%;
  font-size: 20px;
}

.article-row-2 .owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: #531f18;
}


.article-row-2 .owl-theme .owl-dots .owl-dot span {
  width: 12px;
  height: 12px;
  margin: 5px 7px;
  background: #d19c65;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 200ms ease;
  border-radius: 30px;
}

.ar6-carousel .owl-dots .owl-dot span {
  width: 12px;
  height: 12px;
  margin: 5px 7px;
  background: #d19c65;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 200ms ease;
  border-radius: 30px;
}



.footer-top {
  position: relative;
}

.footer-top-content {
  background-color: #ffebd3;
  padding: 20px;
  position: absolute;
  z-index: 1;
  width: 80%;
  left: 50%;

  transform: translateX(-50%);
  top: -70px;
}

.icon-part {
  background: #541f19;
  padding: 15px;
  border-radius: 50%;
  width: 65px;
  margin-top: -52px !important;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.icon-part img {
  width: 25px;
}

.footer-top-inner h3 {
  font-size: 20px;
  margin-top: 35px;
  color: #1c1c1c;
}

.footer-top-inner h3 {
  color: #3d3d3d;
}

.footer-row-1 {
  background-color: #541f19;
}

.footer-row-1 .footer-link {
  text-align: center;
}

.footer-row-1 .footer-link ul {
  padding-left: 0;
}

.footer-row-1 .footer-link li {
  display: inline;
  padding: 0 25px;
  font-size: 16px;
}

.footer-row-1 .footer-link li a {
  color: #fff !important;
}

.footer-row-1 .footer-social-link {
  text-align: center;
}

.footer-row-1 .footer-social-link ul {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 20px;
  padding-left: 0;
}

.footer-row-1 .footer-social-link li a {
  color: #531f18 !important;
  background-color: #fff !important;
  padding: 10px;
  font-size: 16px;
  text-align: center;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.footer-row-1 .footer-social-link li a:hover {
  color: #fff;
  background-color: #d19c65;
}

.footer-row-2 {
  background-color: #490a01;
}

.footer-row-2 .footer-left p {
  color: #fff;
  font-size: 16px;
}

.footer-row-2 .footer-right p {
  color: #fff;
  font-size: 16px;
}

.footer-row-2 .font2 {
  color: #d19c65 !important;
}

.footer-row-2 .font2:hover {
  color: #fff;
}

.footer-row-1 .footer-link li a:hover {
  color: #d19c65;
}


::selection {
  color: #fff !important;
  background-color: #d19c65 !important;
}


.dropdown-menu[data-bs-popper] {
  margin-top: 0 !important;
}


.breadcrumb-article-1:before {
  position: absolute;
  content: "";
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  z-index: 1;
}



.breadcrumb-article-1 .breadcrumb-inner a {
  color: #fff !important;
  font-size: 18px;
}

.breadcrumb-article-2:before {
  position: absolute;
  content: "";
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  z-index: 1;
}



.breadcrumb-article-2 .breadcrumb-inner a {
  color: #fff !important;
  font-size: 18px;
}

.breadcrumb-item+.breadcrumb-item::before {
  color: #ffffff !important;
  font-size: 18px;
}



.about-article-2 .about-image-1 {
  width: calc(100% - 200px);
  margin-right: auto;
}

.about-article-2 .about-experience {
  position: absolute;
  background: #ffeedd;
  padding: 20px 30px;
  right: 0;
  top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42%;
  grid-gap: 20px;
}




.about-article-2 .about-experience img {
  width: 40px;
}

.about-article-2 .about-experience h5 {
  text-align: left;
  color: #d19c65;
  font-weight: 800;
  margin: 0;
  font-size: 26px;
}

.about-article-2 .about-experience h5 span {
  display: block;
  padding-top: 10px;
  font-size: 20px;
  color: #1c1c1c;
  font-weight: 600;
}



.about-article-2 .about-ar3-right-image {
  position: relative;
}

.about-article-2 .about-image-video-section {
  position: absolute;
  bottom: 50px;
  right: 0;
}

.about-article-2 .about-image-video-section img {
  width: 240px;
}

.about-article-2 .about-image-video-section .ar7-vdo-btn {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  bottom: inherit;
}

.about-article-3 {
  background-color: #ffeedd;
}

.about-article-3 .counter-box {
  display: block;
  background: #fff;
  padding: 30px 20px 30px;
  text-align: center;
  border: 1px solid #531f18;
  height: 100%;
}

.about-article-3 .counter-box:hover {
  background-color: #d19c65;
}

.about-article-3 .counter-box:hover p {
  color: #ffffff;
}

.about-article-3 .counter-box p {
  margin: 5px 0 0;
  padding: 0;
  color: #3d3d3d;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 25px;
}

.about-article-3 .counter-box img {
  width: 60px;
  margin: 0 0 15px;
}

.about-article-3 .counter {
  display: block;
  font-size: 26px;
  font-weight: 700;
  color: #531f18;
  line-height: 40px;
}

.about-article-4 {
  position: relative;
}

.about-article-4 ul#myTab {
  flex-direction: column;
  border: none;
  position: absolute;
  right: -50px;
  top: 50%;
  transform: translatey(-50%);
  z-index: 1;
}

.about-article-4 ul#myTab .nav-item {
  display: inline;
  padding: 20px 0;
}

.about-article-4 ul#myTab .nav-item .nav-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 10px;
  padding: 20px 30px;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  color: #1c1c1c !important;
  background-color: #fff !important;
  border-color: #531f18 #531f18 #531f18 !important;
}




.about-article-4 .nav-tabs .nav-item.show .nav-link,
.about-article-4 .nav-tabs .nav-link.active {

  color: rgb(255, 255, 255) !important;
  background: rgb(83, 31, 24);
  border-color: rgb(83, 31, 24) !important;
  flex-direction: column;

  color: #ffffff;
  background-color: #531f18;
  border-color: #531f18 #531f18 #531f18;
}


.about-ar6-carousel .owl-dots .owl-dot span {
  width: 12px;
  height: 12px;
  margin: 5px 7px;
  background: #d19c65 !important;
  display: block;
  -webkit-backspace-visibility: visible;

  transition: opacity 200ms ease !important;
  border-radius: 30px;
}










.about-article-4 ul#myTab .nav-item .nav-link img {
  width: 50px;
}



.about-article-4 .about-ar4-right-content {
  display: flex;
  height: 100%;
  padding: 50px 0 20px 80px;
}

.about-article-4 .about-ar4-right-content .section-title img {
  width: 180px;
}

.about-article-4 .about-ar4-right-content .section-title h3 {
  margin: 0;
}

.about-article-4 .about-ar4-design-section img {
  position: absolute !important;
  width: 400px !important;
  right: 0;
  opacity: 0.5;
  bottom: 0px !important;
}
.about-article-5 {
  background-color: #ffeedd;
}

.about-article-5 .testimonial-item {
  padding: 30px 10px;
  box-shadow: 0px 0px 10px #e3e3e3;
  -webkit-box-shadow: 0px 0px 10px #e3e3e3;
  margin: 20px;
  margin-bottom: 0px;
  border-radius: 5px;
  height:100%;
  background: #fff;
}
.about-article-5 .testimonial-item .title {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  padding: 0 15px;
}
.about-article-5 .testimonial-item .title h4 {
  font-size: 20px;
}

.about-article-5 .testimonial-item .title p {
  margin: 0;
}

.about-article-5 .testimonial-item .review {
  display: flex;
  align-items: center;
  justify-content: end;
  grid-gap: 5px;
  color: #fdc109;
}

.about-article-5 .testimonial-item .description {
  padding-top: 20px;
}

.about-ar5-carousel .owl-prev {
  top: 42%;
  left: -70px;
  position: absolute;
  transform: translateY(-50%);
  color: #ffeedd;
  font-size: 40px !important;
  background: #531f18 !important;
  border: 1px solid #531f18 !important;
  border-radius: 50% !important;
  width: 50px;
  height: 50px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  font-size: 20px !important;
}

.about-ar5-carousel .owl-next {
  top: 42%;
  right: -70px;
  position: absolute;
  transform: translateY(-50%);
  color: #ffeedd;
  font-size: 40px !important;
  background: #531f18 !important;
  border: 1px solid #531f18 !important;
  border-radius: 50% !important;
  width: 50px;
  height: 50px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  font-size: 20px !important;
}

.about-ar5-carousel .owl-next:hover,
.about-ar5-carousel .owl-prev:hover {
  background: #ffeedd !important;
  color: #531f18;
}

.about-ar5-carousel .owl-next:hover i,
.about-ar5-carousel .owl-prev:hover i {
  background: #ffeedd !important;
  color: #531f18;
}

.about-ar5-carousel .owl-nav [class*="owl-"]:hover {
  background: none;
}

.about-ar5-carousel .owl-nav [class*="owl-"] {
  background: none;
}

.about-ar5-carousel {
  position: relative;
}

.about-ar5-carousel .owl-prev i,
.about-ar5-carousel .owl-next i {
  color: #ffeedd;
}

.about-ar6-carousel .owl-dots .owl-dot span {
  width: 12px;
  height: 12px;
  margin: 5px 7px;
  background: #d19c65;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 200ms ease;
  border-radius: 30px;
}

.about-ar6-carousel .owl-dots .owl-dot.active span,
.about-ar6-carousel .owl-dots .owl-dot:hover span {
  background: #531f18 !important;
}

.about-article-4 #myTab {
  flex-direction: column;
  border: none;
  position: absolute;
  left: -120px;
  top: 50%;
  transform: translatey(-50%);
  z-index: 1;
}

.about-article-4 #myTab .nav-item .nav-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 10px;
  padding: 20px 30px;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  color: #1c1c1c;
  background-color: #fff;
  border-color: #531f18 #531f18 #531f18;
}

.about-article-4 #myTab .nav-item {
  display: inline;
  padding: 20px 0;
}


.about-article-4 #myTab .nav-item .nav-link img {
  width: 50px;
}

element.style {
  color: rgb(255, 255, 255) !important;
  background: rgb(83, 31, 24);
  border-color: rgb(83, 31, 24);
  flex-direction: column;
}









.themes-article-2 figure.ar2-figure {
  position: relative;
  margin-bottom: 100px;
}

.themes-article-2 .zoom-img {
  overflow: hidden;
  position: relative;
}

.themes-article-2 figure figcaption {
  position: absolute;
  background: #fff;
  bottom: -70px;
  left: 50%;
  transform: translatex(-50%);
  width: 75%;
  padding: 20px 30px;
  box-shadow: 0 3px 10px #afafaf;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 150px;
}

.themes-article-2 figure figcaption h5 {
  color: #531f18;
  margin-bottom: 10px;
  font-size: 20px;
}

.themes-article-2 figure img {
  width: 100%;
  height:100%;
  /* height: 400px; */
  object-fit: cover;
  transition: transform 1000ms;
}

.themes-article-2 figure:hover img {
  transform: scale(1.15);
}

.themes-article-2 figure figcaption a {
  font-size: 16px;
  line-height: 20px;
  padding: 10px 20px;
}

.breadcrumb-article-1 {
  background: url('../Components/images/breadcrumb.jpg');  
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;
}
.breadcrumb-article-2 {
  
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;
}

.themes-details-article-2 .ar2-figure {
  position: relative;
}

.themes-details-article-2 .ar2-figure figcaption {
  position: absolute;
  right: 50px;
  text-align: center;
  background: #541f19;
  color: #fff;
  padding: 15px 25px;
  bottom: -60px;
  font-size: 30px;
  font-weight: 700;
}

.themes-details-article-2 .ar2-figure figcaption span {
  display: block;
  font-weight: 400;
  font-size: 20px;
}

.themes-details-article-2 .share-icon-section {
  padding: 10px 20px;
  display: flex;
  grid-gap: 20px;
}

.themes-details-article-2 .share-icon-section a {
  color: #fff !important;
  padding: 8px 12px;
  font-size: 14px;
}

.themes-details-article-2 .share-icon-section a i {
  padding-right: 10px;
}

.themes-details-article-2 .share-icon-section .facebook-icon-share {
  background: #4267b2;
}

.themes-details-article-2 .share-icon-section .twitter-icon-share {
  background: #3c9cf3;
}

.themes-details-article-2 .share-icon-section .pinterest-icon-share {
  background: #E60023;
}

.themes-details-article-2 .theme-details-gallery {
  overflow: hidden;
  position: relative;
  box-shadow: 0 0 10px #c3c3c3;
}

.themes-details-article-2 .theme-details-gallery img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 1000ms;
}

.themes-details-article-2 .theme-details-gallery:hover img {
  transform: scale(1.15);
}


.article-row-2 .owl-theme .owl-dots .owl-dot span {
  width: 12px;
  height: 12px;
  margin: 5px 7px;
  background: #d19c65;
  display: block;
  -webkit-backface-visibility: visible;

  transition: opacity 200ms ease;
  border-radius: 30px;
}





/*extra code*/


.service-article-2 figure.ar2-figure:hover figcaption {
  background: #531f18;
}

.service-article-2 figure.ar2-figure:hover figcaption h5 {
  color: #fff;
}


.service-article-2 figure:hover img {
  transform: scale(1.15);
}

.services-deails.php h5 {
  font-size: 30px;
}




/* service-details */
.service-details-ar3-left-content p span {
  color: #531f18;
  font-weight: 500;
}

.service-details-article-2 .gallery-item {
  overflow: hidden;
  position: relative;
}

.service-details-article-2 .gallery-item:hover img {
  transform: scale(1.15);
}




.service-details-article-2 .widget .category-list a {
  display: block;
  padding: 10px 0;
  transition: all 0.5s ease-in-out;
}

.service-details-article-2 .category-list {
  padding: 0px 30px 20px 30px;
}

.service-details-article-2 .category-list a {
  color: #3d3d3d;
}

.service-details-article-2 .widget .category-list a i {
  margin-right: 5px;
}

.service-details-article-2 .fa-angle-double-right:before {
  content: "\f101";
}

.service-details-article-2 .widget .category-list a:hover {
  padding-left: 10px;
  color: #531f18;
  font-weight: 500;
}

.service-details-article-2 .service-details-last {
  padding: 0 0 15px 0;
}

.article-row-1 .carousel-indicators {
  display: none !important;
}





/* gallery */
.gallery-article-2 ul#myTab {
  justify-content: center;
  border: 0;
  padding-bottom: 20px;
}

.gallery-article-2 .nav-tabs .nav-item {
  padding: 10px;
  padding-top: 0px;
}

.gallery-article-2 .nav-tabs .nav-link {
  font-size: 18px;
  padding: 10px 30px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  color: #6c6c6c !important;
  border-color: #e4e4e4;
  background-color: #f9f9f8;
  position: relative;
  text-transform: uppercase;
}

.gallery-article-2 .nav-tabs .nav-item.show .nav-link,
.gallery-article-2 .nav-tabs .nav-link.active {
  color: #ffffff !important;
  background-color: #531f18;
  border-color: #531f18;
}

.gallery-article-2 .nav-tabs .nav-item .nav-link.active:before {
  display: block !important;
  content: "";
  width: 0;
  height: 0;
  border: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid;
  position: absolute;
  bottom: -10px;
  left: 50%;
  -khtml-transform: translateX(-50%) translateY(0%);
  -moz-transform: translateX(-50%) translateY(0%);
  -ms-transform: translateX(-50%) translateY(0%);
  -o-transform: translateX(-50%) translateY(0%);
  transform: translateX(-50%) translateY(0%);
}

.gallery-article-2 .nav-tabs .nav-item .nav-link.active:before {
  border-top-color: #531f18 !important;
}




/* contact us */
.contact-article-2 img.small-size-title-image {
  width: 200px;
}

.contact-article-2 .contact-ar2-left {
  border: 1px solid #531f18;
  padding: 30px 20px 10px;
  height: 100%;
}

.contact-article-2 h5 {
  font-size: 20px;
  font-weight: 700;
  color: #1c1c1c;
  text-transform: uppercase;
}

.contact-article-2 .contact-ar2-left figure {
  display: flex;
  align-items: flex-start;
  grid-gap: 15px;
}

.contact-article-2 .contact-ar2-left figure img {
  padding: 5px;
  border: 1px solid #531f18;
}

.contact-article-2 .contact-ar2-left figure figcaption h6 {
  font-size: 18px;
}

.contact-article-2 .contact-ar2-right {
  border: 1px solid #531f18;
  padding: 30px 30px 10px;
  height: 100%;

  background-color: #fffbf6;
}

.contact-article-2 form .form-control {
  padding: 25px 20px;
  font-size: 15px;
  color: #848484;
  border: 1px solid #eee;
  border-radius: 0;
  font-weight: 500;
}

.contact-article-2 form input:focus,
.contact-article-2 form .form-control:focus {
  outline: none !important;
  border-color: #d19c65;
  box-shadow: none !important;
}

.contact-article-2 form #message {
  padding: 15px 20px;
}

.contact-article-2 form input::placeholder {
  font-size: 14px;
  font-weight: 400;
}

.contact-article-2 form textarea::placeholder {
  font-size: 14px;
  font-weight: 400;
}

.contact-article-2 form .has-error span {
  color: red;
  font-size: 13px;
}

.error {
  color: red;
}



/* .col-lg-6 {
  flex: 0 0 auto;
  width: 50%;
  height: 30px;
}
.g-3, .gy-3 {
  --bs-gutter-y: 20px;
}
.col-lg-12 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
}
.g-3, .gy-3 {
  --bs-gutter-y: 15px;
  padding: 15px;
} */
.btn {
  margin-bottom: 100px;

}

.captcha {
  height: 40px;
}



.sticky-nav {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100% !important;
  z-index: 20px;
  background: #d19c65;
  transition: 0.1s;
}

.navbar {
  position: relative;
  width: 100%;
}

.scroll-to-top {
  background-color: rgb(209 156 101) !important;
  right: 15px !important;
  bottom: 17px !important;
  position: fixed !important;
  z-index: 2 !important;
  cursor: pointer !important;
  border-radius: 0px !important;
  width: 40px !important;
  height: 40px !important;
  box-shadow: 0 9px 25px 0 rgba(132, 128, 177, 0.28);
  border: none !important;
}

.scroll-to-top:hover {
  background-color: #541f19 !important;
  color: #d19c65 !important;
  transition-duration: 1s;


}




.dropdown_menu .dl,
ol,
ul {
  margin-top: -3px;
  margin-bottom: 1rem;
  padding-left: 0px !important;
}

.service-article-2 figure.ar2-figure {
  position: relative;
  margin-bottom:25px;
}


.service-article-2 img {
  width: 100%;
}

.service-article-2 .zoom-img {
  overflow: hidden;
  position: relative;
}


.service-article-2 figure figcaption {
  position: absolute !important;
  background: #fff;
  bottom: -11px;
  left: 50%;
  transform: translatex(-50%);
  width: 75%;
  padding: 20px 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 3px 10px #afafaf;
}

.service-article-2 figure figcaption h5 {
  color: #531f18;
  margin: 0px;
  font-size: 20px;
}

.service-article-2 figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 1000ms;
}






.breadcrumb-article-1 h2 {
  font-size: 45px;
  color: #fff;
  text-transform: uppercase;
}

.breadcrumb-article-1 .breadcrumb-inner {
  background: none;
  justify-content: center;
  padding: 0;
  margin: 0;
}

.breadcrumb-article-2 h2 {
  font-size: 45px;
  color: #fff;
  text-transform: uppercase;
}

.breadcrumb-article-2 .breadcrumb-inner {
  background: none;
  justify-content: center;
  padding: 0;
  margin: 0;
}



.breadcrumb-item.active {
  color: #d19c65 !important;
  font-size: 18px;
}




.breadcrumb-article-1 .breadcrumb-section {
  position: relative;
  z-index: 1;
}

.breadcrumb-article-2 .breadcrumb-section {
  position: relative;
  z-index: 1;
}

/* service-details */

.service-details-article-2 .gallery-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 1000ms;
}


.service-details-article-2 .widget {
  background: #fff;
  margin-bottom: 30px;
  border: #541f19 1px solid;
}

.service-details-article-2 .widget .widget-title {
  color: #fff;
  padding: 15px 30px;
  position: relative;
  background-color: #541f19;
  font-size: 20px;
}


.navbar-nav .dropdown-menu {

  --bs-dropdown-border-width: 0px !important;
}


.sticky-nav {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100% !important;
  z-index: 1000px;
  background: #d19c65;
  transition: 0.1s;
}

.navbar {
  position: relative;
  width: 100%;
}

.number {
  color: black !important;
}

.nav-link:hover {
  color: #531f18;
}

.hom:hover {
  color: #531f18;
}


.nav-toggle-btn>a {
  display: none;
}

.navbar-expand-lg .navbar-nav .dropdown-menu {
  position: inherit;
}

.servise_btn:hover .dropdown_menu-7 {
  display: block;
}

.navbar-expand-lg .navbar-nav .dropdown-menu {
  position: inherit;
}

.dropdown-toggle {
  margin: 0;
  background: none !important;
  border: none !important;
  
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0 !important;
  vertical-align: 0 !important;
  content: "";
  border-top: 0 !important;
  border-right: 0 !important;
  border-bottom: 0 !important;
  border-left: 0 !important;
}

.navbar-light .navbar-nav .active>.nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar .nav-item:hover .nav-link .dropdown-icon {
  color: #531f18;
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}

.dropdown_menu .dropdown-item {
  font-size: 14px;
  padding: 8px 20px;
  display: block;
  
}



.dropdown-menu {
  border-radius: 0 !important;
}

.contact-article-2 .contact-ar2-left figure a {
  color: #3d3d3d;
  word-break: break-word;
}

.contact-article-2 .contact-ar2-left figure a:hover {
  color: #531f18;
}

.imges {
  height: 200px;
  width: 30%;
}

.imgelogo {
  width: 60px;
  height: 60px;
}

.tab_clrs {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 10px;
  padding: 20px 30px;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  color: #1c1c1c;
  background-color: #fff;
  border: 1px solid #531f18;
}
.tab_clrs:hover{
  color: #1c1c1c;
}
.tab_clrs_active {
  color: #ffffff !important;
  background-color: #531f18 !important;
  border: 1px solid #531f18 !important;
}
.tab_clrs img{
  width: 50px;
}
b, strong {
  font-weight: bolder;
  color:  #531f18 !important;

}
.themedetail_numleft {
  margin-left: 8px !important;
}
.dropdown_inner:hover .dropdown_item_1{
  color: white !important;
  
}
.er{
width: 250px;
}

.btn.style12 {
  background-color: #d19c65 !important;
  color: white !important;
}
.figcap
{
position: absolute;
  background: #fff;
   bottom: 2px; 
   left: 50%; 
   transform: translatex(-50%); 
  width: 55%;
  padding: 20px 30px;
  box-shadow: 0 3px 10px #afafaf;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.aboutthemepa
{

width: 100px !important;
}
.about-article-6 .gallery-item img
{

width: 100% !important;
display: flex;
justify-content: center !important;
}
.about-article-6 figcaption .style12 {
  font-size: 16px;
  line-height: 20px;
  padding: 10px 20px;
}
.article-row-6 .owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: sandybrown;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 200ms ease;
  border-radius: 30px;
}
.article-row-6 .owl-theme .owl-dots .owl-dot span :hover {
  background:brown;
}
.toogle{
  margin-left: 50px;
}
.sub-title-menu
{
  margin-left: 40px;
  display:none !important;
}
.title-menu:hover .sub-title-menu{
  display:block !important;
 
}
.sub-title-menu:hover{
  color: #490a01 !important;
}
.hh{
  margin: auto;
  margin-top: 200px;
}
.youtube-icon-share{
  background-color: #FF0000;
}
.linkedin-icon-share{
  background-color: #0166FF;
}

.Insta-icon-share{
  background: radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%);
}

.header-social-link .footer-social-link ul li a {
  color:#531f18 !important;
  background-color: #d19c65 !important;
  padding: 10px;
  font-size: 16px;
  text-align: center;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.header-social-link .footer-social-link ul li a:hover {
  color:#fff  !important;
  background-color:#531f18   !important;
  transition: 0.6s;
}

.header-row-2 .head .enquire-button{
  display: flex;
  align-items:center;
}

.header-row-2 .head .enquire-button a{ 
color: #fff !important;
background-color: #531f18;
padding: 10px;
border-radius: 6px;
}

.header-row-2 .head .enquire-button a:hover{ 
  color:#531f18 !important;
  background-color:#fff;
  font-weight: 500;
  }

  .article-row-1 .carousel-caption a{
    color:#531f18 !important;
    background-color:#fff;
    padding: 10px;
    border-radius: 6px;
    font-weight: 500;
  }
  .article-row-1 .carousel-caption a:hover{
    color:#fff !important;
    background-color:#d19c65;
    font-weight: 500;
  }

.dropdown_inner_two .sub-title-menu{
      margin-left: 0px;
}

.dropdown_inner_one{
  position: relative;
}

 .dropdown_inner_two{
  background-color:#fff;
  color: #000 !important;
  
} 






.dropdown_inner_one{
  background-color: #fff;
}

.dropdown_inner_one:hover{
  background-color: #d19c65;
  color:#d19c65;
}



/* .dropdown_menu_scroll::-webkit-scrollbar {
  display: none;
}

.dropdown_menu_scroll::-webkit-scrollbar-thumb {
   display: none;
}

.dropdown_menu_scroll:hover::-webkit-scrollbar-thumb {
   display: none;
} */

.footer-top-inner a{
  color: #3d3d3d;
}

.dropdown_inner_one a{
  color:#000;
}



.dropdown_inner_two .dropdown_inner a{
  color: #000 !important;
}

 .dropdown_inner a .text{
   color: #000;
}

.dropdown_inner_two a .text-one{
  color: #000;
}

 .dropdown_menu .dropdown_inner_one .dropdown-item:hover .dropdown_inner_two a .text-one{
   color: #fff !important;
} 



.dropdown_inner_two .dropdown_inner a .textone:hover{
  color: #fff;
}
.mobile-view{
  display: none;
}
.webview{
  margin: auto;
}
.footer-top-inner a:hover{
  color: #3d3d3d;
}
.about-article-6 .figcap figcaption h5 a{
  color: #3d3d3d;
}



.breadcrumb-article-1_sub_title {
  color: #d19c65 !important;
  font-size: 18px;
}

.breadcrumb-article-1_sub_title::before {
  content: "/";
  color: #fff;
  padding: 0px 8px;
}


._sub_title::before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, "/");
  color: #fff;
  font-size: 18px;
  padding-left: 0.5rem;
}

.breadcrumb-article-2_sub_title {
  color: #d19c65 !important;
  font-size: 18px;
}

.breadcrumb-article-2_sub_title::before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, "/");
  color: #fff;
  font-size: 18px;
  padding-left: 0.5rem;
}

.about-article-6 .figcap figcaption h5{
    font-size: 20px;
}

.testimonial-slider .img-part img{
  width: fit-content;
}

.article-row-5 .owl-theme .owl-nav{
  margin-top: 0px;
}



.article-row-6 .owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 0px;
}

.service-article-2 .grid-dividing:last-child{
  margin-bottom: 0px;
}

.service-article-2 .grid-dividing:last-child .ar2-figure {
  position: relative;
  margin-bottom: 0px;
}

 .themes-article-2 .grid-dividing{
  margin-bottom: 20px;
}

 .themes-article-2 .grid-dividing:last-child{
  margin-bottom: 0px;
}

.themes-article-2 .grid-dividing:nth-last-child(2){
  margin-bottom: 0px;
}  

.footer{
  margin-top: 102px;
}

.gallery-article-2 .tab-content .grid_dividing:last-child{
  margin-bottom: 0px;
}



.gallery-article-2 .tab-content .grid_dividing:nth-last-child(2){
  margin-bottom: 0px;
}

.gallery-article-2 .tab-content .grid_dividing:nth-last-child(3){
  margin-bottom: 0px;
}

.about-article-4 .about-ar4-right-content p small big{
  font-size: 16px;
}
.article-row-3 .left_align .section-title{
   display: none;
}

.article-row-4 .left_align .ar4-right-image{
  display: none;
}

.about-article-2 .right_align .about-ar3-right-image{
  display: block;
}
.about-article-2 .left_align .about-ar3-right-image{
  display: none;
}

.themes-details-article-2  p h3{
  font-size: 18px;
}
.dropdown-menu_one ul li span{
  color: #000;
  padding-right: 20px;
}

.dropdown-menu_one{
  background-color: #fff;
  padding: 10px 10px;
}

.dropdown-menu_sub{
  padding-left: 20px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.dropdown-container_sub{
  padding: 0px 6px;
}

.dropdown-toggle .title{
  padding: 0px 0px 0px 40px;
}

.dropdown-toggle .dropdown-icon{
 padding-left: 20px;
}

.dropdown_main_sec{
  padding: 20px 0px;
}

.dropdown-menu_sub ul li{
  padding: 10px 0px;
}

.dropdown-container_sub .dropdown-icon_one{
      position: absolute;
      right: 30px;
}

.dropdown-container_sub{
  position: relative;
}

.mobile-view .dropdown_main_sec .dropdown-menu_one ul li a span{
      font-size: 14px;
}

.themes-details-article-2  h3{
  font-size: 40px;
}

.footer-mob-b {
  margin-bottom: unset;
  padding-bottom: 10px;
}
















  


 

 




  

  
 

